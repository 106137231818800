import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AppRoutes from "./AppRoutes";
import theme from "./styles/Theme";
import "./index.css";
import useScreenSize from "./hooks/UseScreenSize";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  const isMobile = useScreenSize();

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <style>{isMobile ? "html, body { min-width: unset; }" : ""}</style>
            <AppRoutes />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
